import React, { Component } from 'react';
import { Container } from 'reactstrap';
import './Footer.css';
import fb from './Images/fb.webp';

export class Footer extends Component {
  static displayName = Footer.name;

  render() {
    return (
      <footer>
        <Container>
          <p className="footerText">Znajdź nas na <a target="blank" href="https://www.facebook.com/Przystanek-Terapia-Wroc%C5%82aw-Psie-Pole-108300967209493/"><img width="20" height="20" alt="facebook" src={fb} /></a> Przystanek Terapia al. Marcina Kromera 5/2 Wrocław, 506 338 897 (w godz. 9:00-19:00) <a href="mailto:kontakt@przystanekterapia.com">kontakt@przystanekterapia.com</a></p>
          <p>Strona używa plików cookies wyłącznie do celów analitycznych. Kontynuując, wyrażasz zgodę na ich wykorzystywanie.</p>
        </Container>
      </footer>
    );
  }
}