import React from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';

import './TherapistTile.css';

export default function TherapistTile(props) {
  return (
    <div className="therapist">
      <NavLink tag={Link} to={props.link}>
        <h4 style={props.headerStyle}>{props.name}</h4>
        <img
          alt={props.name}
          src={props.largeImage}
          srcSet={`${props.image} 230w, ${props.largeImage} 500w`}
          sizes="(min-width: 760px) 230px, 60vw"
        ></img>
      </NavLink>
    </div>
  );
}