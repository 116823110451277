import React, { Component } from 'react';
import { Helmet } from 'react-helmet'
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './Information.css';
import { InformationStructuredData } from './InformationStructuredData.js'
import MagdalenaPiosekImage from './Images/MagdalenaPiosek.webp';
import PaulinaWilkImage from './Images/PaulinaWilk.webp';

export class Information extends Component {
  static displayName = Information.name;

  render() {
    return (
      <div>
        <Helmet>
          <title>Informacje Psycholog Psychoterapeuta Wrocław Przystanek Terapia</title>
          <meta name="description" content="Konsultacje, psychoterapia indywidualna, terapia par, rodzin, dzieci. Profesjonalna terapia w niższej cenie dla studentów." />

          <meta property="og:title" content="Informacje Psycholog Psychoterapeuta Wrocław Przystanek Terapia" />
          <meta property="og:description" content="Konsultacje, psychoterapia indywidualna, terapia par, rodzin, dzieci. Profesjonalna terapia w niższej cenie dla studentów." />
          <meta property="og:url" content="https://www.psychologpsiepole.com/informacje" />
          <meta property="og:site_name" content="Informacje" />
          <meta property="og:type" content="website" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Informacje Psycholog Psychoterapeuta | Wrocław | Przystanek Terapia" />
          <meta name="twitter:description" content="Konsultacje, psychoterapia indywidualna, terapia par, rodzin, dzieci. Profesjonalna terapia w niższej cenie dla studentów." />

          <link rel="canonical" href="https://www.psychologpsiepole.com/informacje" />
        </Helmet>
        <h1 className="informationHeaderText">Zakres oferowanej pomocy</h1>
        <h2 className="informationSubHeaderText">Specjaliści Przystanku Terapia, pomogą Ci poradzić sobie z trudnościami takimi jak:</h2>

        <ul className="helpListStandardText">
          <li>kryzysy życiowe i depresja,</li>
          <li>stres, lęk i nerwice,</li>
          <li>trudności w relacjach,</li>
          <li>zaburzenia odżywiania,</li>
          <li>zaburzenia osobowości i choroby psychiczne.</li>
        </ul>

        <p className="standardText">Zapraszamy na konsultacje w gabinecie przy</p>
        <p className="standardText" style={{ marginBottom: 10 + 'px' }}><a target="blank" href="https://google.pl/maps/place/Gabinet+Psychologiczny+Przystanek+Terapia/@51.1311943,17.0629719,19z/data=!4m8!1m2!2m1!1spsycholog+wrocław+psie+pole!3m4!1s0x0:0x63ea71a1786f0b7e!8m2!3d51.1311943!4d17.0629719">al. Kromera 5/2 we Wrocławiu</a> oraz online.</p>

        <div style={{ float: 'left', width: 100 + '%', textAlign: 'center', marginTop: 10 + 'px', marginBottom: 10 + 'px' }}>
          <div className="navbar-brand">
            <NavLink className="contactButton" tag={Link} to="/kontakt">
              UMÓW WIZYTĘ
            </NavLink>
          </div>
        </div>

        <h2 className="informationSubHeaderText">Obecnie wolne terminy są u Magdaleny Piosek-Grzęda oraz Pauliny Wilk.</h2>

        <NavLink tag={Link} to="/magdalena-piosek">
          <img width="300" height="380" alt="Magdalena Piosek-Grzęda " style={{ marginBottom: 10 + 'px' }} src={MagdalenaPiosekImage} />
        </NavLink>
        <br/>
        <NavLink tag={Link} to="/paulina-wilk">
          <img width="300" height="380" alt="Paulina Wilk" style={{ marginBottom: 10 + 'px' }} src={PaulinaWilkImage} />
        </NavLink>

        <InformationStructuredData />
      </div>
    );
  }
}