import React, { Component, Suspense } from 'react';
import { Helmet } from 'react-helmet'
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Notifications } from 'react-push-notification';
import addNotification from 'react-push-notification';
import { ContactStructuredData } from './ContactStructuredData.js'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './Contact.css';
import Pin from './Images/pin.webp';
import Phone from './Images/phone.webp';
import Mail from './Images/mail.webp';
import Money from './Images/money.webp';
import FB from './Images/fb.webp';
import Clock from './Images/clock.webp';
import PinSmall from './Images/pinSmall.webp';
import PhoneSmall from './Images/phoneSmall.webp';
import MailSmall from './Images/mailSmall.webp';
import MoneySmall from './Images/moneySmall.webp';
import FBSmall from './Images/fbSmall.webp';
import ClockSmall from './Images/clockSmall.webp';

const OpenStreetMap = React.lazy(() => import('./OpenStreetMap/OpenStreetMap.js'));

function successNotification() {
  addNotification({
    title: 'Skopiowano numer konta',
    message: 'Pomyślnie skopiowano numer konta do schowka',
    theme: 'light',
    closeButton: "X",
    backgroundTop: "#419999",
    backgroundBottom: "#F7F267"
  })
};

export class Contact extends Component {
  static displayName = Contact.name;

  render() {
    window.scrollTo(0, 0);

    return (
      <div>
        <Helmet>

          <title>Kontakt Psycholog Psychoterapia Wrocław Psie Pole</title>
          <meta name="description" content="Gabinet psychologiczny, psychoterapia - Przystanek Terapia. Telefon: 506 338 897, email: kontakt@przystanekterapia.com adres: al. Marcina Kromera 5/2 Wrocław." />

          <meta property="og:title" content="Kontakt Psycholog Psychoterapia Wrocław Psie Pole" />
          <meta property="og:description" content="Gabinet psychologiczny, psychoterapia - Przystanek Terapia. Telefon: 506 338 897, email: kontakt@przystanekterapia.com adres: al. Marcina Kromera 5/2 Wrocław." />
          <meta property="og:url" content="https://www.psychologpsiepole.com/kontakt" />
          <meta property="og:site_name" content="Kontakt" />
          <meta property="og:type" content="website" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Kontakt Psycholog Psychoterapia Wrocław Psie Pole" />
          <meta name="twitter:description" content="Gabinet psychologiczny, psychoterapia - Przystanek Terapia. Telefon: 506 338 897, email: kontakt@przystanekterapia.com adres: al. Marcina Kromera 5/2 Wrocław." />

          <link rel="canonical" href="https://www.psychologpsiepole.com/kontakt" />
        </Helmet>
        <h1 className="contactHeaderText">Gabinet Psychologiczny, Psycholog, Psychoterapia Wrocław</h1>
        <h2 className="contactSubHeaderText">Przystanek Terapia</h2>
        <h2 className="contactSubHeaderText">Pracujemy w gabinecie, a także online przez Skype.</h2>

        <div className="contactLeftPane">
          <div className="contactDataItem">
            <p className="contactDataItemLink">
              <img
                src={Clock}
                srcSet={`${ClockSmall} 15w, ${Clock} 30w`}
                sizes="(min-width: 760px) 30px, 4vw"
                className="contactDataImage" alt="Godziny" />
              <span className="contactData">Pn-Pt 9:00-19:00</span>
            </p>
          </div>
          <div className="contactDataItem">
            <a className="contactDataItemLink" target="blank" href="https://google.pl/maps/place/Gabinet+Psychologiczny+Przystanek+Terapia/@51.1311943,17.0629719,19z/data=!4m8!1m2!2m1!1spsycholog+wrocław+psie+pole!3m4!1s0x0:0x63ea71a1786f0b7e!8m2!3d51.1311943!4d17.0629719">
              <img src={Pin}
                srcSet={`${PinSmall} 25w, ${Pin} 40w`}
                sizes="(min-width: 760px) 40px, 6vw"
                className="contactDataImage" alt="pinezka" />
              <p className="contactData">al. Marcina Kromera 5/2<br />51-163 Wrocław (Psie Pole)</p></a>
          </div>
          <div className="contactDataItem">
            <a className="contactDataItemLink" target="blank" href="tel:506338897">
              <img src={Phone}
                srcSet={`${PhoneSmall} 25w, ${Phone} 40w`}
                sizes="(min-width: 760px) 40px, 6vw"
                className="contactDataImage" alt="telefon" /><p className="contactData">506 338 897</p></a>
          </div>
          <div className="contactDataItem">
            <a className="contactDataItemLink" target="blank" href="mailto:kontakt@przystanekterapia.com">
              <img src={Mail}
                srcSet={`${MailSmall} 25w, ${Mail} 40w`}
                sizes="(min-width: 760px) 40px, 6vw"
                className="contactDataImage" alt="email" />
              <p className="contactData">kontakt@przystanekterapia.com</p></a>
          </div>
          <div className="contactDataItem">
            <CopyToClipboard text={"76 1140 2004 0000 3302 7657 0098"} onCopy={() => successNotification()}>
              <p className="contactDataItemLink">
                <img
                  src={Money}
                  srcSet={`${MoneySmall} 25w, ${Money} 40w`}
                  sizes="(min-width: 760px) 40px, 6vw"
                  className="contactDataImage" alt="Numer konta do przelewu" />
                <span className="contactData">76 1140 2004 0000 3302 7657 0098</span>
              </p>
            </CopyToClipboard>

          </div>
          <div className="contactDataItem">
            <a className="contactDataItemLink" target="blank" href="https://m.me/przystanekterapiaWroclaw/">
              <img src={FB}
                srcSet={`${FBSmall} 25w, ${FB} 40w`}
                sizes="(min-width: 760px) 40px, 6vw"
                className="contactDataImage" alt="facebook" /><p className="contactData">Napisz do na na Facebook Messanger</p></a>
          </div>
        </div>
        <Notifications />
        <div className="contactRightPane">
          <Suspense fallback={<div className="openStreetMap suspenseFallback">Trwa ładowanie mapy...</div>}>
            <div className="openStreetMap">
              <OpenStreetMap />
            </div>
          </Suspense>
        </div>
        <div style={{ float: 'left', marginTop: 25 + 'px' }}>
          <p className="contactStandardText"><a target="blank" href="https://www.wroclaw.pl/rozklady-jazdy">Dojazd MPK:</a></p>
          <h2 className="contactSubHeaderText">Szybki dojazd z okolicznych osiedli: Zakrzów, Śródmieście, Sołtysowice, Karłowice, Różanka, Kowale, Nadodrze.</h2>
          <p className="contactStandardText"><a target="blank" href="https://www.wroclaw.pl/rozklady-jazdy"><strong>Tramwaje:</strong> 11, 6, 23</a></p>
          <p className="contactStandardText"><a target="blank" href="https://www.wroclaw.pl/rozklady-jazdy"><strong>Autobusy:</strong> N, A, 130, 128, 147, 151, 116, 118</a></p>
        </div>

        <div style={{ float: 'left', marginTop: 10 + 'px' }}>
          <h2 className="contactSubHeaderText">Szybki dojazd z okolicznych miejscowości tj. Kiełczów, Długołęka, Mirków, Wilczyce, Domaszczyn.</h2>
          <p className="contactStandardText"><a target="blank" href="https://www.wroclaw.pl/rozklady-jazdy"><strong>Autobusy:</strong> 904, 914, 914a, 924, 930</a></p>
        </div>

        <div style={{ float: 'left', width: 100 + '%', textAlign: 'center', marginBottom: 25 + 'px', marginTop: 25 + 'px' }}>
          <div className="navbar-brand">
            <NavLink className="contactButton" tag={Link} to="/cennik">
              CENNIK
            </NavLink>
          </div>
        </div>

        <ContactStructuredData />
      </div >
    );
  }
}