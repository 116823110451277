import React, { Component } from 'react';
import sygnet from './Images/sygnet.webp';
import MagdalenaPiosekImage from './Images/MagdalenaPiosek.webp';
import PaulinaWilkImage from './Images/PaulinaWilk.webp';

export class InformationStructuredData extends Component {
  static displayName = InformationStructuredData.name;

  render() {
    let schemaData = {
      "@context": "https://schema.org",
      "@graph": [{
        "@type": "Organization",
        "logo": `${sygnet}`,
        "url": "https://www.psychologpsiepole.com",
        "image": `${MagdalenaPiosekImage}`,
        "contactPoint": {
          "@type": "ContactPoint",
          "contactType": "Reservations",
          "email": "kontakt@przystanekterapia.com",
          "telephone": "+48 506 338 897",
          "areaServed": "PL",
          "availableLanguage": "Polish"
        },
        "name": "Przystanek terapia",
        "telephone": "+48 506 338 897",
        "address": {
          "streetAddress": "al. Marcina Kromera 5/2",
          "addressLocality": "Wrocław",
          "postalCode": "51-163",
          "addressCountry": "PL",
          "@type": "PostalAddress"
        },
        "sameAs": [
          "https://www.facebook.com/Przystanek-Terapia-Wrocław-Psie-Pole-108300967209493/"
        ]
      },
      {
        "@context": "https://schema.org/",
        "@type": "Person",
        "name": "Magdalena Piosek-Grzęda",
        "jobTitle": "Psychologist",
        "url": "https://www.psychologpsiepole.com/magdalena-piosek",
        "image": `${MagdalenaPiosekImage}`
      },
      {
        "@context": "https://schema.org/",
        "@type": "Person",
        "name": "Paulina Wilk",
        "jobTitle": "Psychologist",
        "url": "https://www.psychologpsiepole.com/paulina-wilk",
        "image": `${PaulinaWilkImage}`
      },
      {
        "@type": "GeoCoordinates",
        "latitude": "51.1311943",
        "longitude": "17.0629719"
      }
      ]
    }

    let JsonLd = ({ data }) =>
      <script type="application/ld+json">
        {JSON.stringify(data)}
      </script>;

    return (
      <JsonLd data={schemaData} />
    );
  }
}