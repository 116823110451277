import React, { Component } from 'react';

export class PricelistStructuredData extends Component {
  static displayName = PricelistStructuredData.name;

  render() {
    let schemaData = {
      "@context": "https://schema.org/",
      "@graph": [{
        "name": "Konsultacje psychologiczne",
        "@type": "Product",
        "description": "Konsultacje psychologiczne 50 min",
        "brand": {
          "name": "Konsultacje psychologiczne",
          "@type": "https://schema.org/Service"
        },
        "offers": {
          "@type": "Offer",
          "priceCurrency": "PLN",
          "price": "220",
          "availability": "https://schema.org/InStock",
          "priceValidUntil": "2030-12-31",
          "url": "https://www.psychologpsiepole.com"
        }
      },
      {
        "name": "Psychoterapia indywidualna",
        "@type": "Product",
        "description": "Psychoterapia indywidualna 50 min",
        "brand": {
          "name": "Psychoterapia indywidualna",
          "@type": "https://schema.org/Service"
        },
        "offers": {
          "@type": "Offer",
          "priceCurrency": "PLN",
          "price": "220",
          "availability": "https://schema.org/InStock",
          "priceValidUntil": "2030-12-31",
          "url": "https://www.psychologpsiepole.com"
        }
      },
      {
        "name": "Konsultacje online",
        "@type": "Product",
        "description": "Konsultacje online (skype) 50 min",
        "brand": {
          "name": "Konsultacje online",
          "@type": "https://schema.org/Service"
        },
        "offers": {
          "@type": "Offer",
          "priceCurrency": "PLN",
          "price": "220",
          "availability": "https://schema.org/InStock",
          "priceValidUntil": "2030-12-31",
          "url": "https://www.psychologpsiepole.com"
        }
      },
      {
        "name": "Consultations and psychotherapy in english and french",
        "@type": "Product",
        "description": "Consultations and psychotherapy in english and french 50 min",
        "brand": {
          "name": "Consultations and psychotherapy in english",
          "@type": "https://schema.org/Service"
        },
        "offers": {
          "@type": "Offer",
          "priceCurrency": "PLN",
          "price": "260",
          "availability": "https://schema.org/InStock",
          "priceValidUntil": "2030-12-31",
          "url": "https://www.psychologpsiepole.com"
        }
      },
      {
        "name": "Konsultacja pary",
        "@type": "Product",
        "description": "Konsultacja pary 75 min",
        "brand": {
          "name": "Konsultacja pary",
          "@type": "https://schema.org/Service"
        },
        "offers": {
          "@type": "Offer",
          "priceCurrency": "PLN",
          "price": "300",
          "availability": "https://schema.org/InStock",
          "priceValidUntil": "2030-12-31",
          "url": "https://www.psychologpsiepole.com"
        }
      },
      {
        "name": "Psychoterapia pary",
        "@type": "Product",
        "description": "Psychoterapia pary 75 min",
        "brand": {
          "name": "Psychoterapia pary",
          "@type": "https://schema.org/Service"
        },
        "offers": {
          "@type": "Offer",
          "priceCurrency": "PLN",
          "price": "300",
          "availability": "https://schema.org/InStock",
          "priceValidUntil": "2030-12-31",
          "url": "https://www.psychologpsiepole.com"
        }
      },
      {
        "name": "Terapia niskopłatna",
        "@type": "Product",
        "description": "Terapia niskopłatna 50 min",
        "brand": {
          "name": "Terapia niskopłatna",
          "@type": "https://schema.org/Service"
        },
        "offers": {
          "@type": "Offer",
          "priceCurrency": "PLN",
          "price": "150",
          "availability": "https://schema.org/InStock",
          "priceValidUntil": "2030-12-31",
          "url": "https://www.psychologpsiepole.com"
        }
      }
      ]
    }

    let JsonLd = ({ data }) =>
      <script type="application/ld+json">
        {JSON.stringify(data)}
      </script>;

    return (
      <JsonLd data={schemaData} />
    );
  }
}