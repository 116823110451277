import React from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import { HomeStructuredData } from './HomeStructuredData.js'
import TherapistTile from './TherapistTile.js';
import './Home.css';
import strap from './Images/homestrap.webp';
import ZuzannaSiwek from './Images/ZuzannaSiwek.webp';
import ZuzannaSiwekLarge from './Images/ZuzannaSiwekLarge.webp';
import RenataPawlowska from './Images/RenataPawlowska.webp';
import RenataPawlowskaLarge from './Images/RenataPawlowskaLarge.webp';
import PaulinaWilk from './Images/PaulinaWilk.webp';
import PaulinaWilkLarge from './Images/PaulinaWilkLarge.webp';
import MagdalenaPiosek from './Images/MagdalenaPiosek.webp';
import MagdalenaPiosekLarge from './Images/MagdalenaPiosekLarge.webp';
import MagdalenaLes from './Images/MagdalenaLes.webp';
import MagdalenaLesLarge from './Images/MagdalenaLesLarge.webp';
import AleksandraGnilka from './Images/AleksandraGnilka.webp';
import AleksandraGnilkaLarge from './Images/AleksandraGnilkaLarge.webp';

export default function Home() {
  return (
    <div>
      <Helmet>
        <title>Psycholog Psychoterapia Wrocław Psie Pole Przystanek Terapia</title>
        <meta name="description" content="Zuzanna Siwek, Psycholog, Psychoterapia, Terapia, Wrocław, Psie Pole, Kiełczów, Wilczyce, Długołęka, Trzebnica, Oleśnica." />

        <meta property="og:title" content="Psycholog Wrocław Psie Pole Przystanek Terapia Zuzanna Siwek" />
        <meta property="og:description" content="Zuzanna Siwek, Psycholog, Psychoterapia, Terapia, Wrocław, Psie Pole, Kiełczów, Wilczyce, Długołęka, Trzebnica, Oleśnica." />
        <meta property="og:url" content="https://www.psychologpsiepole.com" />
        <meta property="og:site_name" content="Home" />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Psycholog Wrocław Psie Pole Przystanek Terapia Zuzanna Siwek" />
        <meta name="twitter:description" content="Zuzanna Siwek, Psycholog, Psychoterapia, Terapia, Wrocław, Psie Pole, Kiełczów, Wilczyce, Długołęka, Trzebnica, Oleśnica." />

        <link rel="canonical" href="https://www.psychologpsiepole.com" />
      </Helmet>
      <img width="920" height="261" className="strap" src={strap} alt="Przystanek terapia" />
      <h1 className="strapWelcomeText">Gabinet Psychologiczny - Przystanek Terapia Wrocław</h1>
      <h3 className="headerText">dr Zuzanna Siwek psycholog, psychoterapeuta</h3>
      <p className="standardText" style={{ marginTop: 25 + 'px' }}>Przystanek Terapia to realizacja mojego marzenia o <strong>przytulnym i bezpiecznym gabinecie. Kameralne warunki i terapia skrojona na potrzeby pacjenta</strong> - to jest to, co wyróżnia Przystanek.</p>
      <p className="standardText">Zespół Przystanku Terapia to doświadczeni psychologowie i psychoterapeuci:​</p>
      <div style={{ textAlign: 'center', float: 'left', width: 100 + '%' }}>
        <TherapistTile link="/zuzanna-siwek" name="dr Zuzanna Siwek" largeImage={ZuzannaSiwekLarge} image={ZuzannaSiwek} />
        <TherapistTile link="/renata-pawlowska" name="mgr Renata Pawłowska" largeImage={RenataPawlowskaLarge} image={RenataPawlowska} />
        <TherapistTile link="/paulina-wilk" name="mgr Paulina Wilk" largeImage={PaulinaWilkLarge} image={PaulinaWilk} />
        <TherapistTile link="/magdalena-piosek" name="mgr Magdalena Piosek-Grzęda" largeImage={MagdalenaPiosekLarge} image={MagdalenaPiosek} />
        <TherapistTile link="/magdalena-les-michalak" name="mgr Magdalena Leś Michalak" largeImage={MagdalenaLesLarge} image={MagdalenaLes} headerStyle={{ fontSize: 15 + 'px', marginTop: 6 + 'px' }} />
        <TherapistTile link="/aleksandra-gnilka" name="mgr Aleksandra Gniłka" largeImage={AleksandraGnilkaLarge} image={AleksandraGnilka} />
      </div>
      <div style={{ width: 100 + '%', float: 'left', textAlign: 'center', marginTop: 25 + 'px', marginBottom: 25 + 'px' }}>
        <div className="bottomButtonDiv">
          <NavLink className="bottomButton bottomButtonLeft" tag={Link} to="/wspolpracownicy">
            ZESPÓŁ
          </NavLink>
        </div>
        <div className="bottomButtonDiv">
          <NavLink className="bottomButton bottomButtonRight" tag={Link} to="/kontakt?amp=1">
            KONTAKT
          </NavLink>
        </div>
      </div>
      <HomeStructuredData />
    </div>
  );
}