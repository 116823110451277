import React from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu/NavMenu';
import { Footer } from './Footer/Footer'

export default function Layout({ children }) {
  return (
    <div>
      <NavMenu />
      <Container>
        {children}
      </Container>
      <Footer />
    </div>
  );
}