import React from 'react';
import { Route } from 'react-router';
import Loadable from 'react-loadable';
import Layout from './components/Layout.js';
import { Loading } from './Loading';
import { Pricelist } from './components/Pricelist/Pricelist';
import { Contact } from './components/Contact/Contact';
import { Information } from './components/Information/Information';
import Home from './components/Home/Home.js';
import './custom.css'

const Team = Loadable({
  loader: () => import('./components/Team/Team.js'),
  loading: Loading,
});
const AleksandraGnilka = Loadable({
  loader: () => import('./components/Team/Members/AleksandraGnilka.js'),
  loading: Loading,
});
const ZuzannaSiwek = Loadable({
  loader: () => import('./components/Team/Members/ZuzannaSiwek.js'),
  loading: Loading,
});
const RenataPawlowska = Loadable({
  loader: () => import('./components/Team/Members/RenataPawlowska.js'),
  loading: Loading,
});
const PaulinaWilk = Loadable({
  loader: () => import('./components/Team/Members/PaulinaWilk.js'),
  loading: Loading,
});
const MagdalenaPiosek = Loadable({
  loader: () => import('./components/Team/Members/MagdalenaPiosek.js'),
  loading: Loading,
});
const MagdalenaLes = Loadable({
  loader: () => import('./components/Team/Members/MagdalenaLes.js'),
  loading: Loading,
});
const HowWeWork = Loadable({
  loader: () => import('./components/HowWeWork/HowWeWork.js'),
  loading: Loading,
});
const Methods = Loadable({
  loader: () => import('./components/Methods/Methods.js'),
  loading: Loading,
});
const QAndA = Loadable({
  loader: () => import('./components/QAndA/QAndA.js'),
  loading: Loading,
});
export default function App() {
  return (
    <Layout>
      <Route exact path='/' component={Home} />
      <Route path='/wspolpracownicy' component={Team} />
      <Route path='/zuzanna-siwek' component={ZuzannaSiwek} />
      <Route path='/renata-pawlowska' component={RenataPawlowska} />
      <Route path='/paulina-wilk' component={PaulinaWilk} />
      <Route path='/magdalena-piosek' component={MagdalenaPiosek} />
      <Route path='/magdalena-les-michalak' component={MagdalenaLes} />
      <Route path='/aleksandra-gnilka' component={AleksandraGnilka} />
      <Route path='/jak-pracuje' component={HowWeWork} />
      <Route path='/metody' component={Methods} />
      <Route path='/pytania-i-odpowiedzi' component={QAndA} />
      <Route path='/cennik' component={Pricelist} />
      <Route path='/kontakt' component={Contact} />
      <Route path='/informacje' component={Information} />
    </Layout>
  );
}