import React, { Component } from 'react';
import sygnet from './Images/sygnet.webp';
import ZuzannaSiwek from './Images/ZuzannaSiwek.webp';

export class HomeStructuredData extends Component {
  static displayName = HomeStructuredData.name;

  render() {
    let schemaData = {
      "@context": "https://schema.org",
      "@graph": [{
        "@type": "Organization",
        "logo": `${sygnet}`,
        "url": "https://www.psychologpsiepole.com",
        "name": "Przystanek terapia",
        "telephone": "+48 506 338 897",
        "image": `${ZuzannaSiwek}`,
        "address": {
          "streetAddress": "al. Marcina Kromera 5/2",
          "addressLocality": "Wrocław",
          "postalCode": "51-163",
          "addressCountry": "PL",
          "@type": "PostalAddress"
        },
        "contactPoint": {
          "@type": "ContactPoint",
          "contactType": "Reservations",
          "email": "kontakt@przystanekterapia.com",
          "telephone": "+48 506 338 897",
          "areaServed": "PL",
          "availableLanguage": "Polish"
        },
        "sameAs": [
          "https://www.facebook.com/Przystanek-Terapia-Wrocław-Psie-Pole-108300967209493/"
        ]
      },
      {
        "@context": "https://schema.org/",
        "@type": "Person",
        "name": "Zuzanna Siwek",
        "jobTitle": "Psychologist",
        "telephone": "+48 506 338 897",
        "url": "https://www.psychologpsiepole.com",
        "image": `${ZuzannaSiwek}`
      },
      {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Współpracownicy",
          "item": "https://www.psychologpsiepole.com/wspolpracownicy"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Renata Pawłowska",
          "item": "https://www.psychologpsiepole.com/renata-pawlowska"
        },
        {
          "@type": "ListItem",
          "position": 3,
          "name": "Paulina Wilk",
          "item": "https://www.psychologpsiepole.com/paulina-wilk"
        },
        {
          "@type": "ListItem",
          "position": 4,
          "name": "Magdalena Piosek",
          "item": "https://www.psychologpsiepole.com/magdalena-piosek"
        },
        {
          "@type": "ListItem",
          "position": 5,
          "name": "Magdalena Leś-Michalak",
          "item": "https://www.psychologpsiepole.com/magdalena-les-michalak"
        },
        {
          "@type": "ListItem",
          "position": 6,
          "name": "Aleksandra Gniłka",
          "item": "https://www.psychologpsiepole.com/aleksandra-gnilka"
        }
        ]
      }
      ]
    }

    let JsonLd = ({ data }) =>
      <script type="application/ld+json">
        {JSON.stringify(data)}
      </script>;

    return (
      <JsonLd data={schemaData} />
    );
  }
}