import React, { Component } from 'react';
import { Helmet } from 'react-helmet'
import './Pricelist.css';
import strap from './Images/priceliststrap.webp';
import { PricelistStructuredData } from './PricelistStructuredData.js'

export class Pricelist extends Component {
  static displayName = Pricelist.name;

  render() {
    return (
      <div>
        <Helmet>
          <title>Cennik Psycholog Psychoterapeuta Wrocław Przystanek Terapia</title>
          <meta name="description" content="Konsultacje, psychoterapia indywidualna, terapia par, rodzin, dzieci. Profesjonalna terapia w niższej cenie dla studentów." />

          <meta property="og:title" content="Cennik Psycholog Psychoterapeuta Wrocław Przystanek Terapia" />
          <meta property="og:description" content="Konsultacje, psychoterapia indywidualna, terapia par, rodzin, dzieci. Profesjonalna terapia w niższej cenie dla studentów." />
          <meta property="og:url" content="https://www.psychologpsiepole.com/cennik" />
          <meta property="og:site_name" content="Cennik" />
          <meta property="og:type" content="website" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Cennik Psycholog Psychoterapeuta | Wrocław | Przystanek Terapia" />
          <meta name="twitter:description" content="Konsultacje, psychoterapia indywidualna, terapia par, rodzin, dzieci. Profesjonalna terapia w niższej cenie dla studentów." />

          <link rel="canonical" href="https://www.psychologpsiepole.com/cennik" />
        </Helmet>
        <img width="920" height="261" className="pricelistStrap" src={strap} alt="Przystanek terapia" />
        <h1 className="pricelistStrapWelcomeText">Wspieramy w rozwoju osobistym i życiowych poszukiwaniach.</h1>
        <h2 className="pricelistHeaderText" style={{ float: 'left', marginTop: 10 + 'px', width: 100 + '%' }}>Cennik</h2>
        <div style={{ float: 'left', marginBottom: 25 + 'px', width: 100 + '%' }}>
          <p className="pricelistStandardText">1. Konsultacje psychologiczne 50 minut - <span className="pricelistPriceText">160 - 220zł</span></p>
          <p className="pricelistStandardText">2. Psychoterapia indywidualna 50 minut - <span className="pricelistPriceText">160 - 220zł</span></p>
          <p className="pricelistStandardText">3. Konsultacje online (skype) 50 minut - <span className="pricelistPriceText">160 - 220zł</span></p>
          <p className="pricelistStandardText">4. Consultations and psychotherapy in english and french 50 minutes - <span className="pricelistPriceText">190 - 260zł</span></p>
          <p className="pricelistStandardText">5. Konsultacja pary 75 minut - <span className="pricelistPriceText">270 - 300zł</span>, 60 min - <span className="pricelistPriceText">200 - 250zł</span></p>
          <p className="pricelistStandardText">6. Psychoterapia pary 75 minut - <span className="pricelistPriceText">270 - 300zł</span>, 60 min. - <span className="pricelistPriceText">200 - 250zł</span></p>
          <p className="pricelistStandardText">7. Badanie Ilorazu inteligencji wraz z opinią - <span className="pricelistPriceText">cena ustalana indywidualnie</span></p>
          <p className="pricelistStandardText">8. Terapia niskopłatna 50 minut - <span className="pricelistPriceText">120 - 150zł</span></p>
          <p className="pricelistStandardText">Warsztaty, terapia grupowa <span className="pricelistPriceText">cena ustalana indywidualnie dla każdego z projektów</span></p>
        </div>

        <PricelistStructuredData />
      </div>
    );
  }
}