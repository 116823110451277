import React, { Component } from 'react';
import { Collapse, Navbar, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './bootstrap.css';
import './NavMenu.css';
import sygnet from './Images/sygnet.webp';
import Phone from './Images/phone.webp';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.collapseNavbar = this.collapseNavbar.bind(this);

    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  collapseNavbar() {
    this.setState({
      collapsed: true
    });
  }

  render() {
    return (
      <header className="header">
        <nav style={{ float: 'left', width: 100 + '%' }}>
          <img className="mainNavLogo" src={sygnet} alt="Przystanek terapia" />
          <div className="mainNavText">
            <h2 className="mainNavHeader">Przystanek terapia</h2>
          </div>
          <a className="contactPhone" target="blank" href="tel:506338897">
            <img src={Phone} alt="telefon" />
          </a>

          <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 navBarWidth" light>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow mainNavMenuText mainNavMenu">
                <NavItem>
                  <NavLink onClick={this.collapseNavbar} tag={Link} className="text-dark" to="/">Strona główna</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink onClick={this.collapseNavbar} tag={Link} className="text-dark" to="/wspolpracownicy">Zespół</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink onClick={this.collapseNavbar} tag={Link} className="text-dark" to="/jak-pracuje">Jak pracujemy?</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink onClick={this.collapseNavbar} tag={Link} className="text-dark" to="/metody">Metody</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink onClick={this.collapseNavbar} tag={Link} className="text-dark" to="/pytania-i-odpowiedzi">Pytania i odpowiedzi</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink onClick={this.collapseNavbar} tag={Link} className="text-dark" to="/cennik">Cennik</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink onClick={this.collapseNavbar} tag={Link} className="text-dark" to="/kontakt">Kontakt</NavLink>
                </NavItem>
              </ul>
            </Collapse>
          </Navbar>
        </nav>

      </header>
    );
  }
}